import constant from "../constants/Constatnt"
import apiKit from "./api-kit"

const checkIsUserRegisterd = (payloadData) => {
    return apiKit.post(`/api/check-if-registered`, payloadData, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
}

const login = (payloadData) => {
    return apiKit.post(`/api/login`, payloadData, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
}

const sendAuthOtp = (payloadData) => {
    return apiKit.post(`api/send-auth-otp`, payloadData, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
}

const signUp = (payloadData) => {
    return apiKit.post(`api/sign-up`, payloadData, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
}

const create_id_transaction = (payloadData) => {
    return apiKit.post(`/api/create-id-transaction`, payloadData, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
}

const deposit_id_transaction = (payloadData) => {
    return apiKit.post(`api/deposit-id-transaction`, payloadData, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
}

const user_transaction = (payloadData, transactionFor) => {
    const getUrl = () => {
        switch (transactionFor) {
            case constant.transactionFor.idDeposit:
                return `/api/deposit-id-transaction`;
            case constant.transactionFor.walletDeposit:
                return `/api/deposit-wallet-transaction`;
            case constant.transactionFor.createIdDeposit:
                return `/api/create-id-transaction`;
            default: return '';
        }
    }
    return apiKit.post(getUrl(), payloadData, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
}

const uploadTransactionImage = (payloadData) => {
    return apiKit.post(`api/upload-transaction-image`, payloadData);
}

const withdrawIdTransaction = (payloadData) => {
    return apiKit.post(`api/withdraw-id-transaction`, payloadData, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
}

const withdrawWalletTransaction = (payloadData) => {
    return apiKit.post(`api/withdraw-wallet-transaction`, payloadData, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
}

const getBanners = () => {
    return apiKit.get(`/api/banners`, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
}

const getWebsitesData = () => {
    return apiKit.get(`/api/websites`, {
        params: {
            all_games: true
        }
    })
}

const getPaymentMethodTypes = () => {
    return apiKit.get(`/api/payment-method-types`, {
        params: {
            user_payment_methods: true
        }
    })
}

const getCompanyMethodTypes = () => {
    return apiKit.get(`/api/company-payment-methods`)
}

const getUpdateMethodTypes = (id, payloadData) => {
    return apiKit.put(`/api/payment-methods/${id}`, payloadData, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
}

const addMethodTypes = (payloadData) => {
    return apiKit.post(`/api/payment-methods`, payloadData, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
}

const deletePaymentMethod = (id) => {
    return apiKit.delete(`/api/payment-methods/${id}`, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
}

const updatePreferrence = (id) => {
    return apiKit.put(`/api/preferred-payment-method/${id}`, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
}

const getTransactionDetails = (pageNo) => {
    return apiKit.get(`api/transactions?pagination=true&page=${pageNo}`)
}

const getTransactionDetailsById = (id) => {
    return apiKit.get(`api/transactions/${id}`)
}

const getUser = () => {
    return apiKit.get(`api/get-user`, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
}

const getUserIds = () => {
    return apiKit.get(`api/user-websites`)
}

const getUserById = (id) => {
    return apiKit.get(`api/user-websites/${id}`)
}

const getConfigs = (payload) => {
    return apiKit.get(`api/configs`, {
        params: {
            category: payload
        }
    })
}

const getCommonConfigs = () => {
    return apiKit.get(`api/common-configs`)
}

const getHelpConfigs = () => {
    return apiKit.get(`api/help-config`)
}

const channgeIdPassword = (payloadData) => {
    return apiKit.post(`api/change-id-password-transaction`, payloadData)
}

const getNotifications = (pageNo) => {
    return apiKit.get(`api/notifications?pagination=true&page=${pageNo}`);
}


const axiosService = {
    checkIsUserRegisterd,
    login,
    sendAuthOtp,
    signUp,
    create_id_transaction,
    deposit_id_transaction,
    user_transaction,
    uploadTransactionImage,
    withdrawIdTransaction,
    withdrawWalletTransaction,
    getBanners,
    getWebsitesData,
    getPaymentMethodTypes,
    getCompanyMethodTypes,
    getUpdateMethodTypes,
    addMethodTypes,
    deletePaymentMethod,
    updatePreferrence,
    getTransactionDetails,
    getTransactionDetailsById,
    getUserIds,
    getUserById,
    getUser,
    getConfigs,
    channgeIdPassword,
    getNotifications,
    getHelpConfigs,
    getCommonConfigs
}

export default axiosService;
