import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import {
    CssBaseline,
    Grid,
    ThemeProvider,
    Typography,
    Stack
} from "@mui/material";
import Toolbar from '@mui/material/Toolbar';
import theme from "../../theme/theme";
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import { useNavigate } from "react-router-dom";
//import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import Person2RoundedIcon from '@mui/icons-material/Person2Rounded';
import CarouselSlider from '../../layouts/Carousel';
import HorizontalScrollComponent from '../../layouts/horizontalScroll';
import Paper from '@mui/material/Paper';
import useMediaQuery from '@mui/material/useMediaQuery';
import AndroidLogo from '../../img/android-icon.svg';
import ReactWhatsapp from "react-whatsapp";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import TelegramIcon from '@mui/icons-material/Telegram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { appTheme } from '../../theme/colorTheme';
import constant from '../../constants/Constatnt';
import { useSelector } from 'react-redux';
import { localStorageKey } from '../../constants/LocalStorageKey';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: appTheme.color11,
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));
function ElevationScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}


const bannerData = [
    {
        "id": 1,
        "image_url": require('../../img/landingPage/landing_slider1.webp'),
        "is_active": true,
        "isStatic": true,
    }
]

const gamesData = [
    {
        "id": 1,
        "image_url": require('../../img/landingPage/landing_tennis.webp')
    },
    {
        "id": 2,
        "image_url": require('../../img/landingPage/landing_soccer.webp')
    },
    {
        "id": 3,
        "image_url": require('../../img/landingPage/landing_cricket.webp')
    },
    {
        "id": 4,
        "image_url": require('../../img/landingPage/landing_7upDown.webp')
    },
    {
        "id": 5,
        "image_url": require('../../img/landingPage/landing_teenPatti.webp')
    }
]

const ourGames = [
    {
        "id": 1,
        "image_url": require('../../img/landingPage/ourGames_7updown.jpeg')
    },
    {
        "id": 2,
        "image_url": require('../../img/landingPage/ourGames_amarakbarantony.jpeg')
    },
    {
        "id": 3,
        "image_url": require('../../img/landingPage/ourGames_andarbahar.jpeg')
    },
    {
        "id": 4,
        "image_url": require('../../img/landingPage/ourGames_bollywood_casino.jpeg')
    },
    {
        "id": 5,
        "image_url": require('../../img/landingPage/ourGames_card_casino.jpeg')
    },
    {
        "id": 6,
        "image_url": require('../../img/landingPage/ourGames_dragontiger.jpeg')
    },
    {
        "id": 7,
        "image_url": require('../../img/landingPage/ourGames_highlow.jpeg')
    },
    {
        "id": 8,
        "image_url": require('../../img/landingPage/ourGames_matka_virtual.jpeg')
    },
    {
        "id": 9,
        "image_url": require('../../img/landingPage/ourGames_patti_oneday.jpeg')
    }, {
        "id": 10,
        "image_url": require('../../img/landingPage/ourGames_poker.jpeg')
    },
    {
        "id": 11,
        "image_url": require('../../img/landingPage/ourGames_race2020.jpeg')
    },
    {
        "id": 12,
        "image_url": require('../../img/landingPage/ourGames_sixplayer_poker.jpeg')
    }
]

const whyFairBaazi = [
    {
        "id": 1,
        "image_url": require('../../img/landingPage/landing_ic1.png'),
        "title": "ONLINE ID",
        "content": "Get your online cricket/sports id with us and instantly start Playing. Message us to get your ID now."
    },
    {
        "id": 2,
        "image_url": require('../../img/landingPage/landing_ic2.png'),
        "title": "24 /7 SUPPORT",
        "content": "We have a team of experts who available 24/7 to guide you through our whatsApp chats."
    },
    {
        "id": 3,
        "image_url": require('../../img/landingPage/landing_ic3.png'),
        "title": "100% TRUSTED SERVICE",
        "content": "Transparency and accuracy is the most important for us and for our customer. We deal in the most transparent way."
    },
    {
        "id": 4,
        "image_url": require('../../img/landingPage/landing_ic4.png'),
        "title": "INSTANT WITHDRAWAL & REFILL",
        "content": `#${constant.websiteName} provides instant service of id withdrawal & refilling with min to max amount.`
    },
    {
        "id": 5,
        "image_url": require('../../img/landingPage/landing_ic5.png'),
        "title": "AVAILABLE PAYMENT METHODS",
        "content": "Multiple methods are available for Paymetns. Customer can pay via Net Banking, Bank Transfer, Paytm, Google pay, IMPS, PhonePe etc."
    },
    {
        "id": 6,
        "image_url": require('../../img/landingPage/landing_ic6.png'),
        "title": "HIGH SECURITY",
        "content": "Security is our Top priority and hence your data and information is safe and is not shared at any Point!"
    }
]

const howItsWorks = [
    {
        "id": 1,
        "image_url": require('../../img/landingPage/landing_1-1.png'),
        "content": "Message us on our WhatsApp number"
    },
    {
        "id": 2,
        "image_url": require('../../img/landingPage/landing_2-1.png'),
        "content": "Customer Satisfy guaranteed with all information"
    },
    {
        "id": 3,
        "image_url": require('../../img/landingPage/landing_3.png'),
        "content": "Start depositing & withdrawal instantly"
    },
    {
        "id": 4,
        "image_url": require('../../img/landingPage/landing_4.png'),
        "content": "Earn from your skills"
    }
]

ElevationScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

const LandingPageScreen = (props) => {
    let navigate = useNavigate();
    const helpLineWhatsAppNumber = useSelector(store => store?.app?.helpLineWhatsAppNumber);
    const whatsAppNumberWithoutPlus = helpLineWhatsAppNumber?.replace('+', '');
    const whatsAppUrl = `https://api.whatsapp.com/send/?phone=${whatsAppNumberWithoutPlus}&text&type=phone_number&app_absent=0`
    const extraSmallDevice = useMediaQuery('(max-width:600px)');
    const whyFairBaaziBoxHeight = !!extraSmallDevice ? 'auto' : 170;
    const howItworksImageHeight = !!extraSmallDevice ? 80 : 130;
    return (
        <React.Fragment>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <ElevationScroll {...props}>
                    <AppBar sx={{
                        maxWidth: "1000px",
                        bgcolor: appTheme.color0,
                        boxShadow: '0px 0px 0px 0px',
                        margin: "0 auto",
                        left: 'auto',
                        right: 'auto',
                        mb: 1,
                        pb: .5
                    }}>
                        <Toolbar>

                            <img
                                style={{
                                    width: `${constant.landingPageLogoSize}`, borderRadius: 5, backgroundColor: appTheme.color11, padding: 4,
                                    justifyContent: "center", alignItems: "center", verticalAlign: 'middle'
                                }}
                                src={require(`../../img/${constant.logoPath}`)} alt="logo"></img>

                            <Box sx={{ flexGrow: 1 }} />
                            <Button
                                startIcon={<Person2RoundedIcon fontSize="small"/>}
                                onClick={() => {
                                    navigate('/login')
                                }}
                                size="small"
                                variant="contained"
                                color="inherit"
                                sx={{
                                    textTransform: 'capitalize',
                                    pr: .7,
                                    pl: .7,
                                    fontSize: 10,
                                    bgcolor: appTheme.color12,
                                    "&:hover": {
                                        bgcolor: appTheme.color12
                                    },
                                    color: appTheme.color0,
                                    focusVisible: false,
                                }}
                            >
                                <Typography component="span" variant="caption" sx={{ml:-1}}>Login</Typography>

                            </Button>
                            <Button
                                onClick={() => {
                                    sessionStorage.removeItem(localStorageKey.USERNUMBER);
                                    navigate('/signup')
                                }}
                                size="small"
                                variant="contained"
                                color="inherit"
                                sx={{
                                    textTransform: 'capitalize',
                                    pr: .7,
                                    pl: .7,
                                    fontSize: 10,
                                    ml:1,
                                    bgcolor: appTheme.color12,
                                    "&:hover": {
                                        bgcolor: appTheme.color12
                                    },
                                    color: appTheme.color0,
                                    focusVisible: false,
                                }}
                            >
                                <Typography component="span" variant="caption">Sign Up</Typography>

                            </Button>
                        </Toolbar>
                    </AppBar>
                </ElevationScroll>
                <Toolbar />
                <Container sx={{ color: appTheme.color1, paddingBottom: 2, mb: 11 }}>
                    <Box sx={{ mt: 1 }}>
                        <CarouselSlider data={bannerData} navigate={navigate} navigationEndpoint={'/login'}/>
                    </Box>
                    <Box>
                        <HorizontalScrollComponent data={gamesData} navigate={navigate} navigationEndpoint={'/login'}/>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center', mt: 2 }}>
                        <Typography variant="h5" gutterBottom >
                            Our Popular Games
                        </Typography>

                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                        <Typography variant="subtitle1" gutterBottom>Gaming is our Passion , We create fun Games that you'll Love.
                        </Typography>
                    </Box>
                    <Box>
                        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                            {ourGames.map((item, index) => (
                                <Grid item xs={2} sm={4} md={4} key={index} onClick={() => {
                                    navigate('/login')
                                }} sx={{cursor:'pointer'}}>
                                    <Item><img src={item.image_url} style={{ width: '100%', display: 'block' }} alt="games" /></Item>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center', mt: 2 }}>
                        <Typography variant="h5" gutterBottom>
                            <span style={{ color: '#FF0000' }}>Why</span> {constant.websiteName}
                        </Typography>
                    </Box>
                    <Box >
                        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                            {whyFairBaazi.map((item, index) => (
                                <Grid item xs={12} sm={4} md={4} key={index} >
                                    <Stack sx={{
                                        backgroundColor: appTheme.color11,
                                        textAlign: 'center',
                                        borderRadius: 1,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        padding: 1,
                                        height: whyFairBaaziBoxHeight
                                    }}>

                                        <img src={item.image_url} style={{ maxHeight: "60px", display: 'block', mb: 2 }} alt="games" />

                                        <Typography variant="body2" gutterBottom color={appTheme.color12}>
                                            {item.title}
                                        </Typography>
                                        <Typography variant="caption" display="block" gutterBottom>
                                            {item.content}
                                        </Typography>

                                    </Stack>

                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center', mt: 2 }}>
                        <Typography variant="h5" gutterBottom>
                            <span style={{ color: '#FF0000' }}>How</span> it wokrs
                        </Typography>
                    </Box>
                    <Box sx={{ pb: 2 }}>
                        <Grid container spacing={{ xs: 0, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                            {howItsWorks.map((item, index) => (
                                <Grid item xs={12} sm={4} md={3} key={index}>
                                    <Stack sx={{
                                        textAlign: 'center',
                                        borderRadius: 1,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',

                                    }}>
                                        <figure>
                                            <img src={item.image_url} style={{
                                                border: '1px solid #F48787',
                                                borderRadius: 100,
                                                maxHeight: `${howItworksImageHeight}px`, display: 'block'
                                            }} alt="games" />
                                        </figure>
                                        <Typography variant="caption" display="block" gutterBottom>
                                            {item.content}
                                        </Typography>

                                    </Stack>

                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                    <Grid item xs={12} md={12} sx={{ mt: 2, mb:2 }}>
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Typography variant="caption" display="block" sx={{
                                        color: appTheme.color6, cursor: 'pointer', "&:hover": {
                                            color: appTheme.color12,
                                            textDecoration:'underline'
                                        }
                                    }} onClick={() => {
                                        navigate('/termsNconditions')
                                    }}>
                                        Terms and Conditions
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Typography variant="caption" display="block" sx={{
                                        color: appTheme.color6, cursor: 'pointer', "&:hover": {
                                            color: appTheme.color12,
                                            textDecoration:'underline'
                                        }
                                    }} onClick={() => {
                                        navigate('/cookiesPolicy')
                                    }}>
                                        Cookies
                                    </Typography>
                                    <Typography variant="caption" display="block" color={appTheme.color6}>&nbsp;&&nbsp;</Typography>
                                    <Typography variant="caption" display="block" sx={{
                                        color: appTheme.color6, cursor: 'pointer', "&:hover": {
                                            color: appTheme.color12,
                                            textDecoration:'underline'
                                        }
                                    }} onClick={() => {
                                        navigate('/privacyPolicy')
                                    }}>
                                        Privacy Policy
                                    </Typography>
                                </Box>
                            </Grid>
                </Container>
                <AppBar position="fixed" color="primary" sx={{
                    top: 'auto', bottom: 0, maxWidth: "1000px", boxShadow: '0px 0px 0px 0px',
                    margin: "0 auto",
                    backgroundColor: `black`,
                    left: 'auto',
                    right: 'auto'
                }}>
                    <Toolbar>
                        <ReactWhatsapp number={helpLineWhatsAppNumber} style={{ padding: 0, backgroundColor: "transparent", border: 'none' }}>
                            <Stack sx={{
                                cursor: 'pointer',
                                padding: .9, backgroundColor: "#0FC555", mr: 1,
                                borderRadius: 100,
                                "&:hover": {
                                    bgcolor: "#0FC555"
                                }
                            }}>
                                <WhatsAppIcon sx={{ color: appTheme.color1 }} />
                            </Stack>
                        </ReactWhatsapp>

                        <Stack onClick={() => {
                            window.open(whatsAppUrl);
                        }} sx={{
                            cursor: 'pointer',
                            padding: .9, borderRadius: 100, backgroundColor: "#FF1717", "&:hover": {
                                bgcolor: "#FF1717"
                            }
                        }}>
                            <InstagramIcon sx={{ color: appTheme.color1 }} />
                        </Stack>

                        {/* <Box
                            onClick={() => {
                                window.open(constant.apkUrl);
                            }}
                            sx={{
                                position: 'absolute',
                                zIndex: 1,
                                top: -30,
                                left: 0,
                                right: 0,
                                margin: '0 auto',
                                cursor: 'pointer',
                                display: 'flex',
                                justifyContent: 'center',
                                width: 160,
                                "&:hover": {
                                    bgcolor: `white`
                                },
                                color: appTheme.color0, bgcolor: `white`,
                                focusVisible: false,
                                padding: 1,
                                borderRadius: 10,
                                textTransform: 'capitalize',
                                pb: 1, pt: 1
                            }} component="label">
                            <Box sx={{ display: 'flex' }}>
                                <img src={AndroidLogo} style={{ width: "25px", marginRight: "5px", verticalAlign: 'middle' }} alt="icon" />
                            </Box>
                            <Box>
                                <Typography variant="caption" display="block">
                                    DOWNLOAD APP
                                </Typography>
                                <Typography variant="caption" display="block" fontSize={10}>
                                    For Android Devices.
                                </Typography>
                            </Box>
                        </Box> */}
                        <Box sx={{ flexGrow: 1 }} />
                        <Stack
                            onClick={() => {
                                window.open(whatsAppUrl);
                            }}
                            sx={{
                                cursor: 'pointer',
                                padding: .9, borderRadius: 100, backgroundColor: "#0CB9DD", mr: 1, "&:hover": {
                                    bgcolor: "#0CB9DD"
                                }
                            }}>
                            <TelegramIcon sx={{ color: appTheme.color1 }} />
                        </Stack>
                        <Stack
                            onClick={() => {
                                window.open(whatsAppUrl);
                            }}
                            sx={{
                                cursor: 'pointer',
                                padding: .9, borderRadius: 100, backgroundColor: "#FF0000", "&:hover": {
                                    bgcolor: "#FF0000"
                                }
                            }}>
                            <YouTubeIcon sx={{ color: appTheme.color1 }} />
                        </Stack>
                    </Toolbar>
                </AppBar>
            </ThemeProvider>
        </React.Fragment>
    );
}

const LandingPage = React.memo(LandingPageScreen);
export default LandingPage;
