import React, { useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Slide from "@mui/material/Slide";
import { DialogContent } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import PaymentScreen from "../../layouts/Payment";
import { appTheme } from "../../theme/colorTheme";

import CloseIcon from '@mui/icons-material/Close';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import constant from "../../constants/Constatnt";
import Marquee from "../../layouts/Marquee";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const FullPaymentDialogScreen = () => {
    let navigate = useNavigate();
    const location = useLocation();
    const [openDialog, setOpenDialog] = React.useState(true);
    const [isFullView, setIsFullView] = React.useState(false);
    const [isUnMute, setIsUnMute] = React.useState(true);
    const [isShowAdverstisment, setIsShowAdverstisment] = React.useState(false);
    const handleDialogClose = () => {
        if (location.key === 'default') {
            navigate('/');
        } else {
            navigate(-1);
        }
        setOpenDialog(false);
    };


    const disableScroll = () => {
        // Get the current page scroll position
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    
        // if any scroll is attempted,
        // set this to the previous value
        window.onscroll = function () {
          window.scrollTo(scrollLeft, scrollTop);
        };
      }
    
      const enableScroll = () => {
        window.onscroll = function () { };
      }

      

    useEffect(() => {
        if (location.key === 'default') {
            navigate('/');
        }
    }, [location.key, navigate]);

    useEffect(() => {
        if (isFullView) {
          disableScroll();
        }else{
          enableScroll();
        }
      }, [isFullView])
    
    useEffect(() => {
        const timer = setTimeout(() => {
            if (!isShowAdverstisment) {
                enableScroll();
                const element = document.getElementById("bottomVideo");
                element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });

            }
        }, 500)

        return () => {
            clearTimeout(timer);
        }
    }, [isShowAdverstisment])
    return (
        <>
            <Dialog
                fullScreen
                open={openDialog}
                sx={{ maxWidth: "600px", margin: '0 auto' }}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: "relative", bgcolor: appTheme.color0, zIndex:1 }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => handleDialogClose()}
                            aria-label="close"
                        >
                            <ArrowBackIosIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="body1" component="div">
                            Payment
                        </Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent sx={{ backgroundColor: appTheme.color0, padding: 0, pb: 1 }}>
                    <PaymentScreen />
                    {/* {
                        !isShowAdverstisment && 
                        <div id="bottomVideo" style={{width:'100%', display:'flex', alignItems:'center',
                        justifyContent:'center', marginTop:20}}>
                        <div className="how-to-pay">
                            <div style={{ textAlign: 'center' , color:appTheme.color1}}>
                            <h4>How to pay from upi to bank account</h4>
                            </div>
                            <video controls>
                                <source src={constant.howToPayBankVid} type="video/mp4" />
                            </video>
                        </div>
                        </div>
                    } */}
                    
                </DialogContent>
                {
          isShowAdverstisment && <div className={isFullView?"electroststicVideoFullView":"electroststicVideo"} >
            {
                !isFullView && <div style={{ width:'100%', padding:0, color: appTheme.color1, textAlign: 'center', zIndex: 9999999999999, 
                position: 'absolute', bottom: -30, }}>
                    <Marquee anoucmentText={'How to pay from upi to bank account'} 
                    isSlowAnimation={true} isLogo={false}/>
                 </div>
            }
            
          <div style={{position:'absolute', top:0, zIndex:2, color:'#fff', width:'100%', display:'flex', justifyContent:'space-between'}}>
            <div className="opacityBg" onClick={() => setIsShowAdverstisment(false)}>
              <CloseIcon/>
            </div>
            <div style={{display:'flex'}}>
            {
             isFullView && <div className="opacityBg mrgnRight" onClick={() => {
              const vid = document.querySelector('video');
              vid.muted = !vid.muted;
              setIsUnMute((prvState => !prvState));
                }}>
               {isUnMute?<VolumeUpIcon />:<VolumeOffIcon/>}
              </div>
            }
            <div className="opacityBg" onClick={() => {
              setIsFullView((prvValue) => !prvValue);
              const vid = document.querySelector('video');
              vid.muted = !vid.muted;
              if(!isUnMute){
                vid.muted = true;
                setIsUnMute(true);
              }
              }}>
            {isFullView ? <CloseFullscreenIcon />: <OpenInFullIcon />}
            </div>
            </div>
            
          </div>
            <video loop autoPlay muted playsInline>
              <source src={constant.howToPayBankVid} type="video/mp4"/>
            </video>
          </div>
        }
       
            </Dialog>

        </>
    );
};
const PaymentDailog = React.memo(FullPaymentDialogScreen);
export default PaymentDailog;
