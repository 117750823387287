
const constant = {
    websiteName:"KheloBuzz",
    baseUrl:"https://pantherwood.in/",
    whatsAppNumber:"+919333600000",
    instagramUrl:"https://www.instagram.com/fairbaazi/",
    telegramUrl:"https://api.whatsapp.com/send/?phone=9333600000&text&type=phone_number&app_absent=0",
    youTubeUrl:"https://www.youtube.com/channel/UCcaunrHXS3IK8UEhQv34uIQ",
    apkUrl:"https://baazi-cdn.sgp1.cdn.digitaloceanspaces.com/fairbaazi/FairBaaziV2.apk",landingPageLogoSize:"70px",
    logoPath:"logo.png",
    minDepositCoins:100,
    minWithdrawalCoins:500,
    howToPayBankVid:"https://baazi-cdn.sgp1.cdn.digitaloceanspaces.com/howtoplay/UpiToBankAccountFairBaazi.mp4",
    numberOfIdsOnHome:15,

    transactionType: {
        depositWallet: 'DepositWalletRequest',
        withdrawWallet: 'WithdrawWalletRequest',
        depositId: 'DepositIdRequest',
        withdrawId: 'WithdrawIdRequest',
        createIdRequest: 'CreateIdRequest',
        changeIdPasswordRequest: 'ChangeIdPasswordRequest',
        closeIdRequest:'CloseIdRequest',
    },
    transactionFor: {
        idDeposit: 'idDeposit',
        walletDeposit: 'walletDeposit',
        createIdDeposit: 'createIdDeposit',
        changePasswordRequest: 'changePasswordRequest'
    },
    accountType: {
        savings: 'savings',
        current: 'current'
    }
}

export default constant;