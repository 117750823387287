import OneSignal from 'react-onesignal';
import { localStorageKey } from './constants/LocalStorageKey';


export default async function runOneSignal() {
  await OneSignal.init({ appId: 'be7aeb14-415c-4bcb-a228-e6613adfa6e8', allowLocalhostAsSecureOrigin: true});
  OneSignal.showSlidedownPrompt();
  OneSignal.getUserId().then((userId) => {
   sessionStorage.setItem(localStorageKey.USERID, userId);
  })
}