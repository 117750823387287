import * as React from 'react';
import CheckboxLabels from '../components/customComponent/Checkbox';
import { Typography } from '@mui/material';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { appTheme } from '../theme/colorTheme';
import { getImgSrc } from '../constants/Helper';

const PaymentModeScreen = (props) => {
  const [selectedPaymentType, setSelectedPaymentType] = React.useState([])


  return (
    <React.Fragment>

      <div style={{ display: 'flex', color: appTheme.color1, justifyContent: 'space-between', fontSize: 11, borderBottom: '1px solid white', padding: '10px 0' }}>
        <div style={{ display: 'flex', color: appTheme.color1 }}>
          <div style={{ marginRight: '10px' }}>Mode</div>
          <div>Display Name</div>
        </div>
        <div>Upi Details</div>
      </div>

      {props.data.map((row) => (
        <div key={row.id} style={{
          display: 'flex', color: appTheme.color1, justifyContent: 'space-between', alignItems: 'center', fontSize: 11, borderBottom: '1px solid white', padding: '10px 0' }}>
          <div style={{ display: 'flex', color: appTheme.color1, alignItems: 'center' }}>
            <div style={{ marginRight: '10px' }}>
              <CheckboxLabels checked={selectedPaymentType?.id === row?.id} onChange={(e, newValue) => {
                if (newValue) {
                  setSelectedPaymentType(row);
                  props.handleChecked(row, newValue);
                } else {
                  setSelectedPaymentType(null)
                  props.handleChecked(null, newValue);
                }
              }} />

            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={!!getImgSrc(row?.logo_url) ? getImgSrc(row.logo_url) : require('../img/no-pictures.png')}
                style={{ verticalAlign: 'middle', borderRadius: 50, width: "30px", marginRight:8 }}

                alt="logo"
                loading="lazy"
              /><Typography variant="caption" gutterBottom fontSize={11} style={{width:"100px", wordWrap:"break-word", overflowWrap: "anywhere"}}>{row.account_display_name}</Typography>
            </div>
          </div>
          <div style={{ width: "180px", wordWrap: "break-word", textAlign: "right", overflowWrap: "anywhere" }}>
            <Typography variant="caption" display="block" gutterBottom fontSize={11}>
              <CopyToClipboard text={row.account_identifier}>
                <span style={{ cursor: 'pointer', fontWeight: 'bold', fontSize: 11 }} onClick={props.handleClick}>
                  {row.account_identifier}&nbsp;
                  <ContentCopyOutlinedIcon fontSize='small' sx={{ fontSize: 14, verticalAlign: 'middle' }} /></span>
              </CopyToClipboard>
            </Typography>
          </div>
        </div>

      ))}
    </React.Fragment>

  )
}

const PaymentMode = React.memo(PaymentModeScreen)

export default PaymentMode;
