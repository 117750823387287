import React from 'react'

import CampaignRoundedIcon from '@mui/icons-material/CampaignRounded';
import { appTheme } from '../theme/colorTheme';
const Marquee = ({anoucmentText, isSlowAnimation=false, isLogo=true}) => {
  return (
    <div className={`${isSlowAnimation?'marqueeSlowAnimation':'marquee'}`}>
    <div style={{display:'flex', alignItems:'center', whiteSpace: 'nowrap'}}>
      {isLogo && <CampaignRoundedIcon fontSize="large" sx={{color:appTheme.color12}}/>}
    {anoucmentText}
    </div>
  </div>
  )
}

export default Marquee